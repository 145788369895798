<script setup lang="ts">

const { loadNavigationElements } = useNavigation();
const { data } = useAsyncData("mainNavigation", () => {
  return loadNavigationElements({ depth: 1 });
});
provide("swNavigation-main-navigation", data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: "footer-navigation",
});
const { data: footerData } = useAsyncData("mainFooterNavigation", () => {
  return loadFooterNavigationElements({ depth: 1 });
});
provide("swNavigation-footer-navigation", footerData);
</script>

<template>
  <div>
    <LayoutCheckoutHeader />
    <LayoutNotifications />
    <main class="max-w-screen-xl mx-auto">
      <slot />
    </main>
    <LayoutFooter />
  </div>
</template>
